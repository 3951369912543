<template>
    <div>
        <!--sagar changes on 12-09-2023 is_Clickable =1 means not able see other staff data  -->
        <router-link :to="link" v-if="row.Is_Clickable==1">{{ row.NAME }}</router-link> 
        <p v-else>{{ row.NAME }}</p> 
    </div>
</template>

<script>
    export default {
        name: "ReferenceCell",
        props: {
            row: {
                type: Object,
                required: true
            }
        },
        computed: {
            link: function () {
                return `/blueBookDetails/${btoa(this.row.id)}`;
            }
        }
    }
</script>
