
import ReferenceCell from '../referenceCell'

export default [
    {
        title: 'Employee Code',
        key: 'EmpCode',
        minWidth: 80,
    },
    {
        title: 'Name of Employee',
        key: 'NAME',
        minWidth: 80,
        render: (h, params) => {
            return h(ReferenceCell, {
                props: {
                    row: params.row
                }
            });
        },
    },
	{
        title: 'Department',
        key: 'Department',
        minWidth: 80,
    },
    {
        title: 'Manager',
        key: 'manager',
        minWidth: 80,
    },
    {
        title: 'Extension Number',
        key: 'Extension',
        minWidth: 80,
    },
    {
        title: 'Reporting Location',
        key: 'ReportingLocation',
        minWidth: 80,
    }
]
